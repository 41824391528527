#home h1 {
  text-align: center;
  position: absolute;
  left: 0;
  top: 70px;
  vertical-align: middle;
  width: 100%;
  margin: 0px;
  padding: 20px 0px 20px 0px;
  background-color: rgb(242 232 225 / 70%);
  color: grey;
}

.cover {
  object-fit: cover;
  object-position: 50% 60%;
  width: 100%;
  height: auto;
  margin: 0px 0px 10px 0px;
  box-shadow: 4px 4px 4px grey;
}

.wedding-date {
  font-family: monospace;
  font-weight: 10000;
  font-size: 40px;
  padding-top: 10px;
}

.description {
  font-family: monospace;
  font-weight: 1000;
  font-size: 30px;
}
.description-small {
  font-size: 15px;
}

.announce {
  color: #ff8585;
  height: 50px;
  line-height: 50px;
  font-family: pacifico;
  font-weight: 400;
  vertical-align: middle;
  padding: 20px 0px 20px 0px;
}

.ampersand {
  font-size: 60px;
  vertical-align: middle;
  margin: 5px;
}
