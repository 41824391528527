html,
body {
  margin: 0;
  height: 100%;
  background-color: #f4ceaf;
  scroll-behavior: smooth;
  font-size: 20px;
}

.anchor {
  position: absolute;
}

nav {
  width: 100vw;
  text-align: center;
  min-height: 70px;
  position: fixed;
  z-index: 9999;
  display: flex;
  background-color: #ff8585;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 20px;
}

.gmnoprint {
  display: none;
}

.material-symbols-outlined {
  color: blue;
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

nav div {
  font-size: 40px;
  font-weight: bold;
  width: 100vw;
  padding: 0px;
  margin: 0px;
  color: white;
}

nav a {
  padding: 5px;
  text-decoration: none;
  color: white;
  font-size: 20px;
}

nav a.selected {
  text-decoration: underline;
  color: #893435;
}

nav a:hover {
  background-color: rgb(214, 135, 135);
  border-radius: 5px;
}

@media (prefers-reduced-motion: no-preference) {
  .square-animation {
    animation: wipe-enter 1s 1;
  }
}

@keyframes wipe-enter {
  0% {
    transform: scale(0, 0.025);
  }
  50% {
    transform: scale(1, 0.025);
  }
}
