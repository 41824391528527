
.selected .material-symbols-outlined{
    color: red;
}

#map {
    height: 25vh;
    width: 100vw;
    position: fixed !important;
    top: 80px;
    left: 0px;
    z-index: 9999;
}
.maps a {
    color: blue;
    cursor: pointer;
    text-decoration: none;
}