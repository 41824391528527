.schedule a {
  text-decoration: none;
  color: rgb(68, 68, 151);
  font-weight: bold;
}

table {
  width: 100%;
  border-collapse: collapse;
}

section {
  padding: 20px;
  text-align: center;
  color: #576b84;
}

.highlight {
  background-color: rgb(251, 249, 134);
}

td {
  text-align: left;
  padding: 10px;
  margin: 0px;
}

td:nth-child(1) {
  font-weight: 1000;
}

td:nth-child(3) {
  text-align: center;
  width: 60%;
}
